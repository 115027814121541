import nhost from "nhost-js-sdk";

// const config = {
//   endpoint: `${process.env.REACT_APP_NHOST_ENDPOINT}`
// };

const config = {
	endpoint: "https://backend-c16l10np.nhost.app"
};

// export default new nhost(config);

nhost.initializeApp(config);

const auth = nhost.auth();
// const storage = nhost.storage();

export { auth };
