import React from "react";
import { Breadcrumb, Row, Col, Icon } from "antd";

const BreadCrumb = (props) => {
	return (
		<Row type="flex" align="bottom" justify="space-between">
			<Col span={12}>
				<div style={{ marginBottom: '1rem' }}>
					{props.backToProjects ? (
						<Breadcrumb>
							<Breadcrumb.Item
								href={`/projects`}
								className="text-decoration-none"
							>
								<div className="d-flex align-items-center justify-content-start">
									<Icon type="left" className="mr-1"/> Back to projects
								</div>
							</Breadcrumb.Item>
						</Breadcrumb>
					) : (
						<Breadcrumb>
							{props.data.split("/").map((data, index) => {
								if (index === props.data.split("/").length - 1) {
									return (
										<Breadcrumb.Item
											className="text-capitalize text-decoration-none"
											key={`key-${index}`}
											separator=" "
										>
											{data.replace(/~~/g, "/")}
										</Breadcrumb.Item>
									);
								} else if (index !== 0) {
									if (data === "projects") {
										return (
											<Breadcrumb.Item
												className="text-capitalize text-decoration-none"
												key={`key-${index}`}
												href={`/projects`}
											>
												{data}
											</Breadcrumb.Item>
										);
									} else {
										return (
											<Breadcrumb.Item
												className="text-capitalize text-decoration-none"
												key={`key-${index}`}
												href={props.link}
											>
												{data}
											</Breadcrumb.Item>
										);
									}
								} else {
									return null
								}
							})}
						</Breadcrumb>
					)}
				</div>
				<h3 style={{ margin: 0 }}>{props.title}</h3>
			</Col>
			<Col span={12}>
				<div style={{ float: 'right' }}>
					{props.children}
				</div>
			</Col>
		</Row>
	);
};

export default BreadCrumb;
