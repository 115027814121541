import React from "react";
import BreadCrumb from "../../components/Breadcrumb";
import { Button, Card, Divider, Popconfirm, Table } from "antd";
import moment from "moment";
import { LinkBtn } from "../../components/StyledComponents";
import { Link } from "react-router-dom";
import { ARCHIVE_PROJECT, GET_ALL_PROJECTS } from "../../graphql";
import { callAPI, showNotification } from "../../utils";
import CreateProject from "./CreateProject";
import EditProject from "./EditProject";
import goProBg from "../../assets/go-pro-bg.png";
import noSubmissions from "../../assets/noSubmissions.svg";

class Projects extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			create_visible: false,
			edit_visible: false,
			project_data: {}
		};
	}

	fetchData = async () => {
		let result = await callAPI(GET_ALL_PROJECTS);
		this.setState({
			projects: result.projects
		});
	};

	archiveProject = async (event, record) => {
		let mutation = ARCHIVE_PROJECT;
		mutation.variables = {
			project_id: record.project_id,
			isActive: false
		};
		let result = await callAPI(mutation);
		if (result.errors) {
			showNotification(
				"error",
				"Something Went Wrong!",
				"Project Cannot Be Archived"
			);
		} else {
			this.fetchData();
		}
	};

	componentDidMount() {
		this.fetchData();
	}

	showEditDrawer = (event, data) => {
		this.setState({
			edit_visible: true,
			project_data: data
		});
	};

	render() {
		const columns = [
			{
				title: "Project Name",
				dataIndex: "name"
			},
			{
				title: "Project ID",
				dataIndex: "project_id"
			},
			{
				title: "Forms",
				dataIndex: "project_forms_aggregate.aggregate.count"
			},
			{
				title: "Created at",
				dataIndex: "created_at",
				render: record => moment(record).format("D MMM, YYYY hh:mm A")
			},
			{
				title: "Action",
				key: "action",
				render: (text, record) => (
					<React.Fragment>
						<LinkBtn
							style={{ color: "#1890ff" }}
							onClick={event =>
								this.showEditDrawer(event, record)
							}
						>
							Edit
						</LinkBtn>
						<Divider type="vertical" />
						<Link
							to={`/projects/${record.id}`}
							className="text-decoration-none"
						>
							View
						</Link>
						{this.props.user.is_pro === "true" && (
							<>
								<Divider type="vertical" />
								<Popconfirm
									title="Are you sure you want to archive this project?"
									onConfirm={event =>
										this.archiveProject(event, record)
									}
									okText="Yes"
									cancelText="No"
								>
									<LinkBtn href="#" style={{ color: "red" }}>
										Archive
									</LinkBtn>
								</Popconfirm>
							</>
						)}
					</React.Fragment>
				)
			}
		];

		return (
			<div className="app-container">
				<BreadCrumb data={`/projects`} title={`Your projects`}>
					{this.state.projects.length !== 0 && (
						<Button
							disabled={
								this.props.user.is_pro !== "true" &&
								this.state.projects.length > 0
							}
							type="primary"
							size="large"
							onClick={() =>
								this.setState({ create_visible: true })
							}
						>
							Create Project
						</Button>
					)}
				</BreadCrumb>
				<div className="mt-4">
					{this.state.projects.length === 0 && (
						<Card className="border rounded">
							<div className="d-flex flex-column align-items-center justify-content-center p-5">
								<img
									src={noSubmissions}
									alt="No Submissions"
									className="mt-5 mb-5"
								/>
								<h4 className="text-light-gray text-center mb-5">
									Looks like you haven’t created any project
									yet. Create your first project by clicking
									below
								</h4>
								<Button
									disabled={
										this.props.user.is_pro !== "true" &&
										this.state.projects.length > 0
									}
									type="primary"
									size="large"
									onClick={() =>
										this.setState({ create_visible: true })
									}
								>
									Create Project
								</Button>
							</div>
						</Card>
					)}
					{this.state.projects.length !== 0 && (
						<Table
							pagination={this.state.projects.length > 10}
							columns={columns}
							rowKey={record => record.id}
							dataSource={this.state.projects}
							className="border rounded"
						/>
					)}
				</div>

				{this.props.user.is_pro !== "true" &&
				this.state.projects.length > 0 ? (
					<div className="d-flex align-items-center w-100 mt-4 mb-4 pl-4 rounded bg-light-blue">
						<img
							className="w-100 h-auto"
							src={goProBg}
							alt="Go Pro Illustration"
						/>
						<div className="position-absolute">
							<h4 className="text-blue font-weight-bold font-22">
								You are currently using free plan
							</h4>
							<p className="text-muted mb-4 font-18">
								Free plan allows only one project and form. To
								create more projects and forms, please upgrade
								to a PRO plan by clicking below
							</p>
							<Button
								type="primary"
								size="large"
								onClick={() =>
									this.props.history.push(`/profile`)
								}
							>
								Check PRO plans
							</Button>
						</div>
					</div>
				) : null}

				{this.state.create_visible && (
					<CreateProject
						visible={this.state.create_visible}
						setVisible={visible =>
							this.setState({ create_visible: visible })
						}
						refetchData={this.fetchData}
					/>
				)}
				{this.state.edit_visible && (
					<EditProject
						visible={this.state.edit_visible}
						setVisible={visible =>
							this.setState({ edit_visible: visible })
						}
						data={this.state.project_data}
						refetchData={this.fetchData}
					/>
				)}
			</div>
		);
	}
}

export default Projects;
