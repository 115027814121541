import React from "react";
import "./App.css";
import Auth from "./Auth";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "./Pages/Login";
import Projects from "./Pages/Projects";
import Form from "./Pages/Projects/FormSubmissions";
import DemoForm from "./Pages/DemoForm";
import Profile from "./Pages/Profile";
import ArchivedProjects from "./Pages/Archived";
import ArchivedForms from "./Pages/Archived/ArchivedForms";
import GenericNotFound from "./Pages/404";
import ProjectForms from "./Pages/Projects/ProjectForms";
import SuccessfulPayment from "./Pages/Payment/success";
import UnsuccessfulPayment from "./Pages/Payment/failure";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import ChangePassword from "./Pages/Login/ChangePassword";

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Login} />
				<Route
					exact
					path="/forgot-password"
					component={ForgotPassword}
				/>
				<Route
					exact
					path="/change-password/:id"
					component={ChangePassword}
				/>
				<Auth exact path="/projects" component={Projects} />
				<Auth exact path="/projects/:id" component={ProjectForms} />
				<Auth exact path="/form/:id" component={Form} />
				<Auth exact path="/profile" component={Profile} />
				<Auth exact path="/archived" component={ArchivedProjects} />
				<Auth
					exact
					path="/archived/projects/:id"
					component={ArchivedForms}
				/>
				<Auth exact path="/demo" component={DemoForm} />
				<Auth
					exact
					path="/payment-success"
					component={SuccessfulPayment}
				/>
				<Auth
					exact
					path="/payment-failure"
					component={UnsuccessfulPayment}
				/>
				<Route exact path="*" component={GenericNotFound} />
			</Switch>
		</Router>
	);
}

export default App;
