import React from "react";
import { Input, Button, Card, Row, Col, Tabs } from "antd";
import { Form, Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { auth } from "../../nhost.js";
import Logo from "../../assets/Illustration.svg";
import { showNotification } from "../../utils";
import "./Login.css";
import { LoginSchema } from "../../validations";

class Login extends React.Component {
	componentDidMount() {
		if (sessionStorage.length > 0) {
			this.props.history.push("/projects");
		}
	}

	registerUser = async (username, password, register_data = {}) => {
		try {
			const register = await auth.register(username, username, password);
			if (register === "OK") {
				await this.authenticateUser(username, password);
				sessionStorage.setItem("user_email", username);
			}
		} catch (e) {
			console.log("err", e);
			// openNotification("error", e.data.message);
		}
	};

	async authenticateUser(username, password) {
		try {
			await auth.login(username, password);
			sessionStorage.setItem("user_email", username);
			this.props.history.push("/projects");
		} catch (e) {
			if (e.status === 401) {
				showNotification("error", "Login Error", "Invalid Credentials");
			}
		}
	}

	handleLogin = values => {
		this.authenticateUser(values.email, values.password);
	};

	handleRegister = values => {
		this.registerUser(values.email, values.password);
	};

	render() {
		return (
			<React.Fragment>
				<div>
					<Row
						align="middle"
						justify="center"
						type="flex"
						className="login-container"
					>
						<Col span={12} className="image-container">
							<img
								src={Logo}
								alt="logo"
								style={{ width: "100%" }}
							/>
							<h1>
								<strong>Form</strong>Lab
							</h1>
							<h3>Forms that don't suck!</h3>
							<h3>Hassle free forms that saves your dev time</h3>
						</Col>
						<Col span={12} className="card-container">
							<Card className="login-card">
								<Tabs size="large" className="tabs-blue">
									<Tabs.TabPane key="login" tab="Log in">
										<Formik
											initialValues={{
												email: "",
												password: ""
											}}
											validationSchema={LoginSchema}
											onSubmit={this.handleLogin}
											enableReinitialize={true}
										>
											{({
												values,
												errors,
												touched,
												handleChange,
												handleBlur
											}) => (
												<Form>
													<div className="form-item">
														<label>
															EMAIL ADDRESS
														</label>
														<Input
															size="large"
															type="text"
															name="email"
															className={
																errors.email &&
																touched.email
																	? "has-error"
																	: ""
															}
															onChange={
																handleChange
															}
															onBlur={handleBlur}
															value={values.email}
														/>
														<div
															style={{
																minHeight: 21
															}}
														>
															<ErrorMessage
																name="email"
																component="div"
																className="error-message"
															/>
														</div>
													</div>
													<div className="form-item">
														<label>PASSWORD</label>
														<Input
															size="large"
															type="password"
															name="password"
															className={
																errors.password &&
																touched.password
																	? "has-error"
																	: ""
															}
															onChange={
																handleChange
															}
															onBlur={handleBlur}
															value={
																values.password
															}
														/>
														<div
															style={{
																minHeight: 21
															}}
														>
															<ErrorMessage
																name="password"
																component="div"
																className="error-message"
															/>
														</div>
													</div>
													<Button
														block
														size="large"
														type="primary"
														htmlType="submit"
														style={{
															background:
																"linear-gradient(264.25deg, #EB9E57 0.66%, #FF800C 99.19%)",
															borderRadius: 8,
															border: "none"
														}}
													>
														Log in
													</Button>
												</Form>
											)}
										</Formik>
										<Link to="/forgot-password">
											{" "}
											<p className="mt-2">
												Forgot Password{" "}
											</p>
										</Link>
									</Tabs.TabPane>
									<Tabs.TabPane key="signup" tab="Sign up">
										<Formik
											initialValues={{
												email: "",
												password: ""
											}}
											validationSchema={LoginSchema}
											onSubmit={this.handleRegister}
											enableReinitialize={true}
										>
											{({
												values,
												errors,
												touched,
												handleChange,
												handleBlur
											}) => (
												<Form>
													<div className="form-item">
														<label>
															EMAIL ADDRESS
														</label>
														<Input
															size="large"
															type="text"
															name="email"
															className={
																errors.email &&
																touched.email
																	? "has-error"
																	: ""
															}
															onChange={
																handleChange
															}
															onBlur={handleBlur}
															value={values.email}
														/>
														<div
															style={{
																minHeight: 21
															}}
														>
															<ErrorMessage
																name="email"
																component="div"
																className="error-message"
															/>
														</div>
													</div>
													<div className="form-item">
														<label>PASSWORD</label>
														<Input
															size="large"
															type="password"
															name="password"
															className={
																errors.password &&
																touched.password
																	? "has-error"
																	: ""
															}
															onChange={
																handleChange
															}
															onBlur={handleBlur}
															value={
																values.password
															}
														/>
														<div
															style={{
																minHeight: 21
															}}
														>
															<ErrorMessage
																name="password"
																component="div"
																className="error-message"
															/>
														</div>
													</div>
													<Button
														block
														size="large"
														type="primary"
														htmlType="submit"
														style={{
															background:
																"linear-gradient(264.25deg, #EB9E57 0.66%, #FF800C 99.19%)",
															borderRadius: 8,
															border: "none"
														}}
													>
														Sign up for free
													</Button>
													<p className="hint">
														By Signing up, I accept
														the{" "}
														<strong>
															Terms of use
														</strong>{" "}
														and{" "}
														<strong>
															Privacy policy
														</strong>{" "}
														of FormLab
													</p>
												</Form>
											)}
										</Formik>
									</Tabs.TabPane>
								</Tabs>
							</Card>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		);
	}
}

export default Login;
