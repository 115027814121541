import React from "react";
import Main from "./main";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "antd/dist/antd.css";

function App() {
	return (
		<div className="App">
			<Main />
		</div>
	);
}

export default App;
