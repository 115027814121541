import React from "react";
import {Button, Divider, Popconfirm, Table} from "antd";
import moment from "moment";
import BreadCrumb from "../../components/Breadcrumb";
import {GET_ARCHIVED_PROJECTS, UN_ARCHIVE_PROJECT} from "../../graphql";
import {callAPI, showNotification} from "../../utils";

class ArchivedProjects extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
		};
	}
	
	fetchData = async () => {
		let result = await callAPI(GET_ARCHIVED_PROJECTS);
		this.setState({ projects: result.projects });
	};
	
	unArchiveProject = async(event, record) => {
		let mutation = UN_ARCHIVE_PROJECT;
		mutation.variables = { project_id: record.project_id };
		let result = await callAPI(mutation);
		if (result.errors) {
			console.log(result);
			showNotification("error", "Something Went Wrong!", "Project Was Not Unarchived")
		} else {
			this.fetchData();
		}
	};
	
	componentDidMount() {
		this.fetchData();
	}
	
	render() {
		const columns = [
			{
				title: "Project name",
				dataIndex: "name"
			},
			{
				title: "Forms archived",
				dataIndex: "project_forms_aggregate.aggregate.count"
			},
			{
				title: "Project ID",
				dataIndex: "project_id"
			},
			{
				title: "Created at",
				dataIndex: "created_at",
				render: record => moment(record).format("D MMM, YYYY hh:mm A")
			},
			{
				title: "Action",
				key: "action",
				render: (text, record) => (
					<React.Fragment>
						<Button
							type="link"
							onClick={() => this.props.history.push(`/archived/projects/${record.id}`)}
						>
							View
						</Button>
						<Divider type="vertical" />
						<Popconfirm
							title="Are you sure you want to unarchive the project?"
							onConfirm={event => this.unArchiveProject(event, record)}
							okText="Yes"
							disabled={this.props.user.is_pro !== "true"}
							cancelText="No"
						>
							<Button type="link" disabled={this.props.user.is_pro !== "true"}>Unarchive</Button>
						</Popconfirm>
					</React.Fragment>
				)
			}
		];
		
		return (
			<div className="app-container">
				<BreadCrumb
					backToProjects
					title={`Archived projects`}
				>
				</BreadCrumb>
				<div className="mt-4">
					<Table
						pagination={this.state.projects.length > 10}
						columns={columns}
						rowKey={record => record.id}
						className="border rounded"
						dataSource={this.state.projects}
					/>
				</div>
			</div>
		);
	}
}

export default ArchivedProjects;
