import React from "react";
import "antd/dist/antd.css";
import { SUBMIT_FORM_DATA } from "../graphql";
import { callAPI } from "../utils";
import {
  Form,
  Input,
  Button,
} from 'antd';
// import axios from "axios";

class RegistrationForm extends React.Component {

  // handleSubmit = e => {
  //   e.preventDefault();
  //   this.props.form.validateFieldsAndScroll(async (err, values) => {
  //     if (!err) {
  //       console.log('Received values of form: ', values);
  //
  //       let mutation = SUBMIT_FORM_DATA;
  //
  //       mutation.variables = {
  //         objects: {data: JSON.stringify(values), form_id: "db50324b-b285-43b1-aa21-02560023ae33"}
  //       };
  //       let result = await callAPI(mutation);
  //       console.log(result);
  //       if (result.errors) {
  //         console.log(result);
  //       } else {
  //         console.log("Done")
  //       }
  //       // await axios
  //       // .post(
  //       //   `https://formlab.netlify.com/.netlify/functions/submit?form_id=f20a64e3-13dc-45df-b1fa-a9124e74063c`,
  //       //   {
  //       //     data: values
  //       //   }
  //       // )
  //       // .then(response => {
  //       //   console.log(response);
  //       // })
  //       // .catch(error => {
  //       //   console.log(error);
  //       // });
  //
  //     }
  //   });
  // };


  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Form {...formItemLayout} onSubmit={this.props.handleSubmit} style={{width:"40%", margin: "0 auto", marginTop: "10vh"}}>

        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please input your Name!',
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item label="E-mail">
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Phone Number">
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please input your phone number!' }],
          })(<Input style={{ width: '100%' }} />)}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: 'register', onFieldsChange(props, changedFields) {
    // props.onChange(changedFields);
    // console.log("PROPS", props);
    // console.log("Changed Fields", changedFields);

    console.log(props.form.getFieldsValue());

    props.handleStateMapping(props.form.getFieldsValue());

  },
  mapPropsToFields(props){
    return {
      name: Form.createFormField({
        value: props.state.form.name
      }),
      email: Form.createFormField({
        value: props.state.form.email
      }),
      phone: Form.createFormField({
        value: props.state.form.phone
      })
    };
  }
})(RegistrationForm);

class DemoForm extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      form: {
        name: "",
        email: "",
        phone: ""
      }
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    let mutation = SUBMIT_FORM_DATA;

    mutation.variables = {
      objects: {data: JSON.stringify(this.state.form), form_id: "db50324b-b285-43b1-aa21-02560023ae33"}
    };
    let result = await callAPI(mutation);
    console.log(result);
    if (result.errors) {
      console.log(result);
    } else {
      console.log("Done");
    }
    this.setState({form: {}})
  };

  handleStateMapping = (values) => {
    this.setState({form: values} )
  }

  render(){
    return (
      <WrappedRegistrationForm state={this.state} handleStateMapping={(values) => this.handleStateMapping(values)} handleSubmit={this.handleSubmit}/>
    )
  }
}

export default DemoForm;
