import React from "react";
import { Icon } from "antd";
import moment from "moment";
import "./index.css";
import { callAPI } from "../../utils";
import { GET_USER_DETAILS, UPDATE_USER, GET_SUB_ID } from "../../graphql";
import ManagePassword from "../../components/ManagePassword";
import DowngradeUser from "../../components/DowngradeUser";
import BreadCrumb from "../../components/Breadcrumb";

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user_id: "",
			email: "",
			is_pro: false,
			plan_type: "",
			selected_plan_type: "",
			billings: [],
			password_visible: false,
			downgrade_visible: false
		};
	}

	fetchData = async () => {
		let user = await callAPI(GET_USER_DETAILS);
		let stripeQuery = GET_SUB_ID;
		stripeQuery.variables = {
			stripe_id: user.users[0].stripe_id ? user.users[0].stripe_id : ""
		};
		let stripeData = await callAPI(stripeQuery);
		this.setState({
			user_id: user.users[0].id,
			email: user.users[0].display_name,
			is_pro: user.users[0].is_pro,
			plan_type: user.users[0].plan_type,
			end_date:
				stripeData.billings.length > 0
					? stripeData.billings[0].end_date
					: null
			// billings: user.users[0].billings
		});
	};

	componentDidMount() {
		this.fetchData();
	}

	onPasswordOpen = () => {
		this.setState({ password_visible: true });
	};

	onDowngradeOpen = () => {
		this.setState({ downgrade_visible: true });
	};

	onDowngradeClose = () => {
		this.setState({ downgrade_visible: false });
		this.props.refreshUserData();
		this.fetchData();
	};

	onClickMonthlyPlan = async () => {
		this.setState({ selected_plan_type: "month" });
		let query = UPDATE_USER;
		query.variables = {
			objects: {
				is_pro: "Pending",
				plan_type: "month"
			}
		};

		await callAPI(query);

		let stripe = window.Stripe(`${process.env.REACT_APP_STRIPE_KEY}`);

		stripe
			.redirectToCheckout({
				items: [
					{
						plan: `${process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID}`,
						quantity: 1
					}
				],
				customerEmail: this.state.email,
				successUrl: `${process.env.REACT_APP_BASE_URL}/payment-success`,
				cancelUrl: `${process.env.REACT_APP_BASE_URL}/payment-failure`
			})
			.then(function(result) {
				if (result.error) {
					// If `redirectToCheckout` fails due to a browser or network
					// error, display the localized error message to your customer.
					// var displayError = document.getElementById("error-message");
					// displayError.textContent = result.error.message;
					console.log("Stripe error", result.error.message);
				}
			});
	};

	onClickYearlyPlan = async () => {
		this.setState({ selected_plan_type: "year" });

		let query = UPDATE_USER;
		query.variables = {
			objects: {
				is_pro: "Pending",
				plan_type: "year"
			}
		};

		await callAPI(query);

		let stripe = window.Stripe(`${process.env.REACT_APP_STRIPE_KEY}`);

		stripe
			.redirectToCheckout({
				items: [
					{
						plan: `${process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID}`,
						quantity: 1
					}
				],
				customerEmail: this.state.email,
				successUrl: `${process.env.REACT_APP_BASE_URL}/payment-success`,
				cancelUrl: `${process.env.REACT_APP_BASE_URL}/payment-failure`
			})
			.then(function(result) {
				if (result.error) {
					// If `redirectToCheckout` fails due to a browser or network
					// error, display the localized error message to your customer.
					// var displayError = document.getElementById("error-message");
					// displayError.textContent = result.error.message;
					console.log("Stripe error", result.error.message);
				}
			});
	};

	render() {
		return (
			<React.Fragment>
				<div className="app-container">
					<BreadCrumb backToProjects title={`My Account`} />
					<div className="profile-container">
						<div className="profile-email">
							<span className="title">EMAIL ADDRESS</span>
							<span className="action-btn">
								{this.state.email}
							</span>
						</div>
						<div className="profile-current-plan">
							<span className="title">CURRENT PLAN</span>
							{this.state.plan_type === "free" && (
								<span className="action-btn">FREE</span>
							)}
							{this.state.plan_type === "month" && (
								<span className="action-btn">
									PRO - MONTHLY
								</span>
							)}
							{this.state.plan_type === "year" && (
								<span className="action-btn">PRO - YEARLY</span>
							)}
						</div>
						{this.state.is_pro === "true" && (
							<div className="current-plan-container">
								<div className="current-plan">
									{/* <span className="title">LAST BILLED</span> */}
									{/* {this.state.billings.length !== 0 && (
										<span className="date">
											{moment(
												this.state.billings[0]
													.created_at
											).format("DD MMMM, YYYY")}
										</span>
									)} */}
									{this.state.end_date && (
										<>
											<span className="title">
												RENEWS ON
											</span>
											<span className="date">
												{moment(
													this.state.end_date
												).format("DD MMMM, YYYY")}
											</span>
										</>
									)}
								</div>
								<span
									className="action-btn"
									onClick={this.onDowngradeOpen}
								>
									DOWNGRADE CURRENT PLAN
								</span>
							</div>
						)}
						<div className="profile-plans">
							<div className="plan-title-container">
								<span className="plan-title">
									{this.state.is_pro === "true"
										? "PRO Unlocked"
										: "Go PRO"}
								</span>
								<span className="plan-desc">
									Unlimited projects and forms, export forms
									for offline
								</span>
							</div>
							<div className="plan-box-container">
								{this.state.is_pro === "true" ? (
									<React.Fragment>
										<div className="plan-box">
											{this.state.plan_type ===
												"month" && (
												<Icon
													type="check"
													className="plan-tick"
												/>
											)}
											<span className="plan-box-title">
												Monthly
											</span>
											<span className="plan-box-amount">
												$5
											</span>
											<span className="plan-box-desc">
												Renews automatically every month
											</span>
										</div>
										<div className="plan-box">
											{this.state.plan_type ===
												"year" && (
												<Icon
													type="check"
													className="plan-tick"
												/>
											)}
											<span className="plan-box-title">
												Yearly
											</span>
											<span className="plan-box-amount">
												$50
											</span>
											<span className="plan-box-desc">
												Renews automatically every year
											</span>
										</div>
									</React.Fragment>
								) : (
									<React.Fragment>
										<div
											className="plan-box"
											onClick={this.onClickMonthlyPlan}
										>
											<span className="plan-box-title">
												Monthly
											</span>
											<span className="plan-box-amount">
												$5
											</span>
											<span className="plan-box-desc">
												Renews automatically every month
											</span>
										</div>
										<div
											className="plan-box"
											onClick={this.onClickYearlyPlan}
										>
											<span className="plan-box-title">
												Yearly
											</span>
											<span className="plan-box-amount">
												$50
											</span>
											<span className="plan-box-desc">
												Renews automatically every year
											</span>
										</div>
									</React.Fragment>
								)}
							</div>
						</div>
						<div className="profile-account-settings">
							<span className="title">ACCOUNT SETTING</span>
							<span
								className="action-btn"
								onClick={this.onPasswordOpen}
							>
								Manage password
							</span>
							{/*<span className="action-btn">Delete account</span>*/}
						</div>
					</div>
				</div>
				{this.state.password_visible && (
					<ManagePassword
						visible={this.state.password_visible}
						onClose={() =>
							this.setState({ password_visible: false })
						}
					/>
				)}
				{this.state.downgrade_visible && (
					<DowngradeUser
						visible={this.state.downgrade_visible}
						user_id={this.state.user_id}
						onClose={this.onDowngradeClose}
						user={this.props.user}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default Profile;
