import axios from "axios";
import { notification } from "antd";
import { auth } from "./nhost";

export const callAPI = async (query = {}, params = {}) => {
	if (sessionStorage.getItem("jwt_token") === null) {
		const jwt_token = auth.getJWTToken();
		sessionStorage.setItem("jwt_token", jwt_token);
	}

	let headers = {
		headers: {
			Authorization: "Bearer " + sessionStorage.getItem("jwt_token")
		}
	};
	console.log(query);
	let res = await axios.post(
		`${process.env.REACT_APP_GQL_ENDPOINT}`,
		query,
		headers
	);
	let { data } = res.data;
	if (res.data.errors) {
		console.log(res.data.errors);
		sessionStorage.clear();
		window.location.href = process.env.REACT_APP_BASE_URL;
	}
	return data;
};

export const callAPIWithAdminSecret = async (query = {}, params = {}) => {
	let headers = {
		headers: {
			"x-hasura-admin-secret": "358c09bed3ce1bf3b4aa8b327bec0093"
		}
	};
	console.log(query);
	let res = await axios.post(
		`${process.env.REACT_APP_GQL_ENDPOINT}`,
		query,
		headers
	);
	let { data } = res.data;
	if (res.data.errors) {
		console.log(res.data.errors);
	}
	return data;
};

export const showNotification = (type, heading = "", message = "") => {
	if (type === "success") {
		notification["success"]({
			message: `${heading}`,
			description: `${message}`
		});
	} else if (type === "error") {
		notification["error"]({
			message: `${heading}`,
			description: `${message}`
		});
	} else {
		notification["info"]({
			message: `${heading}`,
			description: `${message}`
		});
	}
};
