import React from "react"
import {Drawer} from "antd";

class ViewSubmission extends React.Component {
	render() {
		return (
			<Drawer
				title="View Submission"
				onClose={() => this.props.setVisible(false)}
				visible={this.props.visible}
				maskClosable={true}
				width={400}
				destroyOnClose={true}
			>
				<div>
					{Object.keys(this.props.data).length > 0 && (
						<div>
							{Object.keys(this.props.data).map(
								(key, i) => (
									<div key={i}>
										{key} :{" "}
										{this.props.data[key]}
									</div>
								)
							)}
						</div>
					)}
				</div>
			</Drawer>
		);
	}
}

export default ViewSubmission;
