import React from "react";
import { GET_USER_RESET_LINK } from "../../graphql";
import { Input, Button } from "antd";
import { callAPIWithAdminSecret, showNotification, callAPI } from "../../utils";
import { auth } from "../../nhost";

export default function ChangePassword(params) {
	console.log(params.match.params.id);

	const resetPassword = async () => {
		let query = GET_USER_RESET_LINK;
		query.variables = {
			link: params.match.params.id
		};

		let response = await callAPIWithAdminSecret(query);

		if (response.users.length === 1) {
			console.log("Okay");
			let new_password = document.getElementById("password").value;
			let confirm_password = document.getElementById("confirmation")
				.value;

			if (new_password === confirm_password) {
				try {
					await auth.new_password(
						response.users[0].secret_token,
						new_password
					);
					console.log("success");
					showNotification(
						"success",
						"Success!",
						"Your password has been reset"
					);
				} catch (e) {
					// handle error
					console.log("error", e);
				}
			} else {
				showNotification("error", "Error!", "Invalid credentials");
			}
		} else {
			showNotification("error", "Error!", "Invalid Link");
		}
	};

	// resetPassword();

	return (
		<div className="w-25 mx-auto" style={{ marginTop: "10rem" }}>
			<h4 className="font-weight-bold mb-2">Enter New Password</h4>
			<Input.Password
				placeholder="Enter password"
				id="password"
				className="mb-4"
			/>
			<h4 className="font-weight-bold mb-2 mt-2">Confirm New Password</h4>
			<Input.Password
				placeholder="Confirm password"
				id="confirmation"
				className="mb-2"
			/>
			<Button
				onClick={resetPassword}
				type="primary"
				className="w-100 mt-4"
			>
				{" "}
				Change Password{" "}
			</Button>
		</div>
	);
}
