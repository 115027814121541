import React from "react";
import { Button, Typography, Card, Divider, Popconfirm, Table } from "antd";
import { CSVLink } from "react-csv";
import moment from "moment";
import "./index.css";
import {DELETE_SUBMISSION, GET_ALL_SUBMISSIONS, GET_ALL_FORMS} from "../../graphql";
import BreadCrumb from "../../components/Breadcrumb";
import { callAPI } from "../../utils";
import noSubmissions from "../../assets/noSubmissions.svg";
import { LinkBtn } from "../../components/StyledComponents";
import ViewSubmission from "./ViewSubmission";
import goProBg from "../../assets/go-pro-bg.png";

class FormSubmissions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submissions: [],
			submission_visible: false,
			selected_submission: {},
			project_forms_by_pk: {
				name: "",
				project: {
					id: "",
					name: ""
				}
			},
			no_forms: 0
		};
	}

	fetchData = async () => {
		let query = GET_ALL_SUBMISSIONS;
		let getAllForms = GET_ALL_FORMS;
		query.variables = {
			form_id: this.props.match.params.id,
			is_active: true
		};
		let result = await callAPI(query);
		let allForms = await callAPI(getAllForms);
		if (result.errors) {
			console.log(result);
		} else {
			this.setState({
				project_forms_by_pk: result.project_forms_by_pk,
				submissions: result.form_submissions,
				no_forms: allForms.project_forms_aggregate.aggregate.count
			});
		}
	};

	deleteRow = async (index, record) => {
		let query = DELETE_SUBMISSION;
		query.variables = {
			id: record.id
		};
		let result = await callAPI(DELETE_SUBMISSION);
		if (result.errors) {
			console.log(result);
		} else {
			this.fetchData();
		}
	};

	componentDidMount() {
		this.fetchData();
	}

	onRowClick = index => {
		this.setState({
			submission_visible: true,
			selected_submission:
				typeof this.state.submissions[index].data === "string"
					? JSON.parse(this.state.submissions[index].data)
					: this.state.submissions[index].data
		});
	};

	render() {
		let returnData = data => typeof data === "string" ? JSON.parse(data) : data;
		
		let submissions = this.state.submissions.map(sub => ({
			id: sub.id,
			created_at: sub.created_at,
			...returnData(sub.data)
		}));

		let uniques = new Set([]);
		submissions.forEach(item => {
			Object.keys(item).forEach(key => {
				uniques.add(key);
			});
		});

		let headers = [...uniques];

		const columns = [
			{
				title: "Submission ID",
				dataIndex: "id"
			},
			{
				title: "Created at",
				dataIndex: "created_at",
				render: (text) => moment(text).format("MMMM Do YYYY, HH:mm"),
				sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at))
			},
			{
				title: `${headers[2]}`,
				dataIndex: headers[2]
			},
			{
				title: "Actions",
				render: (text, record, index) => (
					<React.Fragment>
						<Button
							type="link"
							onClick={() => this.onRowClick(index)}
						>
							View
						</Button>
						<Divider type="vertical" />
						<Popconfirm
							title="Are you sure you want to delete this entry?"
							onConfirm={event => this.deleteRow(index, record)}
							okText="Yes"
							cancelText="No"
						>
							<LinkBtn href="#" style={{ color: "red" }}>
								Delete
							</LinkBtn>
						</Popconfirm>
					</React.Fragment>
				)
			}
		];

		return (
			<div className="app-container">
				<BreadCrumb
					data={`/projects/${this.state.project_forms_by_pk.project.name || "Project Name"}/${this.state.project_forms_by_pk.name || "Form Name"}`}
					link={`/projects/${this.state.project_forms_by_pk.project.id}`}
					title={this.state.submissions.length !== 0 ? `Submissions - ${this.state.submissions.length}` : "Submissions"}
				>
					{this.state.submissions.length !== 0 && (
						<CSVLink
							filename={`${this.state.project_forms_by_pk.name || "Form Submissions"}.csv`}
							data={submissions}
							headers={[...headers]}
						>
							<Button
								disabled={this.props.user.is_pro !== "true" && this.state.no_forms > 0}
								type="primary"
								size="large"
							>
								Export as CSV
							</Button>
						</CSVLink>
					)}
				</BreadCrumb>
				<div className="mt-4">
					{this.state.submissions.length === 0 && (
						<div className="d-flex flex-column align-items-center justify-content-center p-5">
							<img src={noSubmissions} alt="No Submissions" className="mt-5 mb-5"/>
							<h4 className="text-light-gray text-center mb-5">
								No submissions for this form yet
							</h4>
							<Card className="border rounded">
								<h5 className="mb-4">Configure your form</h5>
								<Typography.Text
									code
									style={{ whiteSpace: "pre-wrap" }}
								>
									{`curl -X POST \n 'https://formlab.dev/.netlify/functions/submit?form_id=${this.props.match.params.id}' \n -H 'Content-Type: application/json' \n -d '{ "key": "value" }'`}
								</Typography.Text>
							</Card>
						</div>
					)}
					{this.state.submissions.length !== 0 && (
						<Table
							pagination={submissions.length > 10}
							columns={columns}
							rowKey={record => record.id}
							dataSource={submissions}
							className="border rounded"
						/>
					)}
				</div>
				{this.props.user.is_pro !== "true" && this.state.no_forms > 0 ? (
					<div className="d-flex align-items-center w-100 mt-4 mb-4 pl-4 rounded bg-light-blue">
						<img className="w-100 h-auto" src={goProBg} alt="Go Pro Illustration"/>
						<div className="position-absolute">
							<h4 className="text-blue font-weight-bold font-22">
								You are currently using free plan
							</h4>
							<p className="text-muted mb-4 font-18">
								To export as CSV, please upgrade to a PRO plan by clicking below
							</p>
							<Button type="primary" size="large" onClick={() => this.props.history.push(`/profile`)}>
								Check PRO plans
							</Button>
						</div>
					</div>
				) : null}
				{this.state.submission_visible && (
					<ViewSubmission
						visible={this.state.submission_visible}
						setVisible={visible =>
							this.setState({ submission_visible: visible })
						}
						data={this.state.selected_submission}
					/>
				)}
			</div>
		);
	}
}

export default FormSubmissions;
