import React from "react";
import { Button, Drawer, Input } from "antd";
import { auth } from "../nhost";
import { callAPI, showNotification, callAPIWithAdminSecret } from "../utils";
import { GET_USER_RESET_LINK, UPDATE_USER_HAVING_EMAIL } from "../graphql";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

class ManagePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			new_password: "",
			confirm_password: "",
			secret_token: ""
		};
	}

	fetchData = async () => {
		let random = uuidv4();

		let updateUserResetLinkQuery = UPDATE_USER_HAVING_EMAIL;
		updateUserResetLinkQuery.variables = {
			objects: {
				secret_token: random,
				secret_token_expires_at: moment()
					.add(1, "days")
					.format()
			}
			// email: document.getElementById("email").value
		};

		// let result = await callAPI(GET_USER_RESET_LINK);

		await callAPI(updateUserResetLinkQuery);

		this.setState({ secret_token: random });
	};

	componentDidMount = () => {
		this.fetchData();
	};

	updateInput = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onClose = () => {
		this.setState({
			new_password: "",
			confirm_password: ""
		});
		this.props.onClose();
	};

	changePassword = async () => {
		if (this.state.new_password === this.state.confirm_password) {
			try {
				auth.new_password(
					this.state.secret_token,
					this.state.new_password
				);
				// setTimeout(() => {
				//   this.props.history.push("/");
				// }, 5000);

				showNotification("success", "Success", "Password Changed!");
				this.onClose();
			} catch (e) {
				// handle error
				console.log(e);
			}
		} else {
			showNotification(
				"error",
				"Password Error",
				"Passwords Do Not Match"
			);
		}
	};

	render() {
		return (
			<Drawer
				title={
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between"
						}}
					>
						<div>Enter New Password</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end"
							}}
						>
							<Button
								type="danger"
								style={{ marginRight: "1rem" }}
								onClick={this.onClose}
							>
								Discard
							</Button>
							<Button
								type="primary"
								onClick={this.changePassword}
							>
								Save
							</Button>
						</div>
					</div>
				}
				onClose={this.onClose}
				visible={this.props.visible}
				maskClosable={true}
				closable={false}
				width={"35%"}
				destroyOnClose={true}
			>
				<label>Enter New Password</label>
				<Input
					name="new_password"
					onChange={this.updateInput}
					type="password"
					value={this.state.new_password}
					style={{ marginBottom: "10px" }}
				/>

				<label>Confirm New Password</label>
				<Input
					name="confirm_password"
					onChange={this.updateInput}
					type="password"
					value={this.state.confirm_password}
				/>
			</Drawer>
		);
	}
}

export default ManagePassword;
