import React from "react";
import { Redirect, Route } from "react-router-dom";
import Nav from "./components/NavBar";
import { callAPI } from "./utils";
import { GET_USER_DETAILS } from "./graphql";

class Auth extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {
				is_pro: "false",
				plan_type: "free",
				stripe_id: null
			},
			loading: true
		};
	}

	fetchData = async () => {
		let result = await callAPI(GET_USER_DETAILS);
		this.setState({
			user: {
				is_pro: result.users[0].is_pro,
				plan_type: result.users[0].plan_type,
				no_forms: result.project_forms_aggregate.aggregate.count,
				stripe_id: result.users[0].stripe_id
			},
			loading: false
		});
	};

	componentDidMount() {
		this.fetchData();
	}

	render() {
		const { component: Component, ...rest } = this.props;

		if (this.state.loading) {
			return "Loading...";
		} else {
			return (
				<Route
					{...rest}
					render={props =>
						sessionStorage.length === 0 ? (
							<Redirect to="/" />
						) : (
							<div>
								<Nav {...props} />
								<Component
									{...props}
									user={this.state.user}
									refreshUserData={this.fetchData}
								/>
							</div>
						)
					}
				/>
			);
		}
	}
}

export default Auth;
