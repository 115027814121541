import React from "react";
import { Layout } from "antd";
import FourOFour from "../assets/undraw_page_not_found_su7k.svg"

class GenericNotFound extends React.PureComponent {

  goBack = () => {
    this.props.history.goBack()
  };

  goHome = () => {
    this.props.history.push(`/dashboard`)
  };

  render() {
    return (
      <div>
        <Layout className="w-100 vh-100">
          <Layout.Content className="content-404">
            <img src={FourOFour} alt="404"/>
            <div>
              <h5>The page you are looking for doesn't exist or an error occurred.</h5>
              <h5>
                <span onClick={this.goBack} className="linksIn404">Go Back</span> or head over to <span onClick={this.goHome} className="linksIn404">Home</span>
              </h5>
            </div>
          </Layout.Content>
        </Layout>
      </div>
    );
  }
}

export default GenericNotFound
