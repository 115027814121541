import React from "react";
import {Button, Divider, Popconfirm, Table} from "antd";
import moment from "moment";
import BreadCrumb from "../../components/Breadcrumb";
import {GET_ARCHIVED_FORMS, UN_ARCHIVE_FORM} from "../../graphql";
import {callAPI, showNotification} from "../../utils";

class ArchivedForms extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			forms: [],
			projects_by_pk: {
				id: "",
				project_id: "",
				name: "",
				isActive: true,
			},
		};
	}
	
	fetchData = async () => {
		let query = GET_ARCHIVED_FORMS;
		query.variables = {
			project_id: Number(this.props.match.params.id),
		};
		let response = await callAPI(query);
		this.setState({
			projects_by_pk: response.projects_by_pk,
			forms: response.project_forms
		});
	};
	
	unArchiveForm = async (event, record) => {
		let mutation = UN_ARCHIVE_FORM;
		mutation.variables = {
			form_id: record.form_id
		};
		let result = await callAPI(mutation);
		if (result.error) {
			showNotification("error", "Something Went Wrong!", "Project Was Not Unarchived");
		} else {
			this.fetchData();
		}
	};
	
	componentDidMount() {
		this.fetchData();
	}
	
	render() {
		const columns = [
			{
				title: "Form Name",
				dataIndex: "name"
			},
			{
				title: "Submissions",
				dataIndex: "form_submissions_aggregate.aggregate.count",
			},
			{
				title: "Form ID",
				dataIndex: "form_id"
			},
			{
				title: "Created at",
				dataIndex: "created_at",
				render: record => moment(record).format("D MMM, YYYY hh:mm A")
			},
			{
				title: "Action",
				key: "action",
				render: (text, record) => (
					<React.Fragment>
						<Button
							type="link"
							onClick={() => this.props.history.push(`/form/${record.form_id}`)}
						>
							View
						</Button>
						<Divider type="vertical" />
						<Popconfirm
							title={this.state.projects_by_pk.isActive ? "Are you sure you want to unarchive this form?" : "Unarchiving a form under an archived project will unarchive that project. The rest of the forms in that project remain archived. Do you want to continue?"}
							placement="top"
							onConfirm={event => this.unArchiveForm(event, record)}
							okText="Yes"
							cancelText="No"
							disabled={this.props.user.is_pro !== "true"}
							overlayStyle={{ width: 350 }}
						>
							<Button type="link" disabled={this.props.user.is_pro !== "true"}>Unarchive</Button>
						</Popconfirm>
					</React.Fragment>
				)
			}
		];
		
		return (
			<div className="app-container">
				<BreadCrumb
					backToProjects
					title={`Archived Forms`}
				>
				</BreadCrumb>
				<div className="mt-4">
					<Table
						pagination={this.state.forms.length > 10}
						columns={columns}
						rowKey={record => record.form_id}
						className="border rounded"
						dataSource={this.state.forms}
					/>
				</div>
			</div>
		);
	}
}

export default ArchivedForms;
