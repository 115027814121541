import React from "react";
import { Input, Button } from "antd";
import { FIND_USERNAME, UPDATE_USER_HAVING_EMAIL } from "../../graphql";
import { callAPIWithAdminSecret, showNotification, callAPI } from "../../utils";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export default function ForgotPassword() {
	const handleSubmit = async () => {
		let query = FIND_USERNAME;
		query.variables = {
			email: document.getElementById("email").value
		};

		let response = await callAPIWithAdminSecret(query);

		if (response.users.length === 1) {
			showNotification(
				"success",
				"Success!",
				"Password reset link has been sent to your e-mail"
			);

			let random = uuidv4();

			let updateUserResetLinkQuery = UPDATE_USER_HAVING_EMAIL;
			updateUserResetLinkQuery.variables = {
				objects: {
					secret_token: random,
					secret_token_expires_at: moment()
						.add(1, "days")
						.format()
				},
				email: document.getElementById("email").value
			};

			await callAPIWithAdminSecret(updateUserResetLinkQuery);

			let json_data = JSON.stringify({
				email: document.getElementById("email").value,
				reset_link: `${process.env.REACT_APP_BASE_URL}/change-password/${random}`
			});

			console.log(json_data);

			axios({
				method: "post",
				url:
					"https://loving-ptolemy-88ba19.netlify.com/.netlify/functions/forgot-password",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
					Accept: "application/json"
				},
				data: json_data
			});
		} else {
			showNotification(
				"error",
				"Invalid Email!",
				"Please check your e-mail address"
			);
		}
	};

	return (
		<div className="w-25 mx-auto" style={{ marginTop: "10rem" }}>
			<h4 className="font-weight-bold mb-2">Enter Your Email</h4>
			<Input placeholder="Enter email" id="email" />
			<Button
				onClick={handleSubmit}
				type="primary"
				className="w-100 mt-4"
			>
				{" "}
				Recover Password{" "}
			</Button>
		</div>
	);
}
