import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
	email: Yup.string().required("Required"),
	password: Yup.string().required("Required"),
});

export const ProjectSchema = Yup.object().shape({
	name: Yup.string().required("Required")
});

export const FormSchema = Yup.object().shape({
	name: Yup.string().required("Required")
});

export const DownGradeUserSchema = Yup.object().shape({
	selected_project: Yup.string().required("Required"),
	selected_form: Yup.string().required("Required")
});
