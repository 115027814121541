import React from "react"
import {Button, Drawer, Input} from "antd";
import {ErrorMessage, Form, Formik} from "formik";
import {ProjectSchema} from "../../validations";
import {EDIT_PROJECT_NAME} from "../../graphql";
import {callAPI} from "../../utils";

class EditProject extends React.Component {
	handleSubmit = async (values) => {
		let mutation = EDIT_PROJECT_NAME;
		mutation.variables = {
			name: values.name,
			project_id: this.props.data.project_id
		};
		let result = await callAPI(mutation);
		if (result.errors) {
			console.log(result);
		} else {
			this.props.setVisible(false);
			this.props.refetchData();
		}
	};
	
	render() {
		return (
			<Drawer
				visible={this.props.visible}
				width={500}
				destroyOnClose={true}
				maskClosable={false}
				className="drawer-underline-orange"
				onClose={() => this.props.setVisible(false)}
				title="Edit Project"
			>
				<Formik
					initialValues={{ name: this.props.data.name }}
					validationSchema={ProjectSchema}
					onSubmit={this.handleSubmit}
					enableReinitialize={true}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
					}) => (
						<Form>
							<div className="form-item">
								<label>Project Name</label>
								<Input
									size="large"
									type="text"
									name="name"
									className={errors.name && touched.name ? "has-error" : ""}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.name}
								/>
								<div style={{ minHeight: 21 }}>
									<ErrorMessage name="name" component="div" className="error-message"/>
								</div>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
								<Button
									size="large"
									style={{ marginRight: '1rem' }}
									onClick={() => this.props.setVisible(false)}
								>
									Cancel
								</Button>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
								>
									Save
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Drawer>
		);
	}
}

export default EditProject;
