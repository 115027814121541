import React, { useEffect } from "react";
import { UPDATE_USER } from "../../graphql";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { callAPI } from "../../utils";
import error from "../../assets/warning-red.png";
import "./style.css";

export default function FailurePage(props) {
	useEffect(() => {
		if (props.user.is_pro === "Pending") {
			let query = UPDATE_USER;
			query.variables = {
				objects: {
					is_pro: "false",
					plan_type: "free"
				}
			};

			callAPI(query);
		} else {
			window.location.href = "/";
		}
	}, [props.user.is_pro]);

	return (
		<div
			style={{
				position: "absolute",
				top: "0",
				left: "0",
				right: "0",
				bottom: "0",
				margin: "auto",
				width: "fit-content",
				height: "fit-content"
			}}
		>
			<div className="w-100">
				<div className="m-0 text-center">
					<img
						src={error}
						style={{
							width: "14rem"
						}}
						alt="Payment Failure!"
					/>
				</div>
			</div>

			<div className="w-75 mx-auto text-center">
				<p id="payment-failure">Sorry, payment failed</p>
				<p id="success-message">
					We are unable to process subscription changes. Don’t worry,
					your account will remain active on its current subscription.
					Please try again after some time.
				</p>
			</div>
			<div className="w-75 mx-auto text-center">
				<Link to="/profile">
					<Button
						style={{
							border: "1px solid #828282",
							boxSizing: " border-box",
							borderRadius: "6px"
						}}
					>
						Back to Profile Page
					</Button>
				</Link>
			</div>
		</div>
	);
}
