import React from "react";
import axios from "axios";
import { Button, Drawer, Select, Alert, Modal } from "antd";
import {
	DOWNGRADE_USER,
	GET_ALL_FORMS_INSIDE_PROJECT,
	GET_ALL_PROJECTS,
	GET_SUB_ID
} from "../graphql";
import { callAPI, showNotification } from "../utils";
import { DownGradeUserSchema } from "../validations";
import { ErrorMessage, Form, Formik } from "formik";

class DowngradeUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			project_forms: [],
			loading: false
		};
	}

	fetchProjects = async () => {
		let result = await callAPI(GET_ALL_PROJECTS);
		this.setState({
			projects: result.projects
		});
	};

	fetchForms = async project_id => {
		this.setState({ loading: true });
		let query = GET_ALL_FORMS_INSIDE_PROJECT;
		query.variables = { project_id };
		let response = await callAPI(query);
		this.setState({
			project_forms: response.project_forms,
			loading: false
		});
	};

	downGradeUser = values => {
		Modal.confirm({
			title: "Are you sure?",
			content: "All your forms will be archived after downgrading",
			okText: "Downgrade Plan",
			cancelText: "Keep PRO plan",
			onOk: async () => {
				let query = GET_SUB_ID;
				query.variables = {
					stripe_id: this.props.user.stripe_id
				};

				let res = await callAPI(query);

				console.log(res);

				// await axios.post("http://localhost:9000/unsubscribe", {
				// 	data: {
				// 		sub_id: res.billings[0].subscription_id
				// 	}
				// });

				axios({
					method: "post",
					url:
						"https://loving-ptolemy-88ba19.netlify.com/.netlify/functions/unsubscribe",
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						Accept: "application/json"
					},
					data: {
						sub_id: res.billings[0].subscription_id
					}
				});

				let mutation = DOWNGRADE_USER;
				mutation.variables = {
					user_id: this.props.user_id,
					project_id: values.selected_project,
					form_id: values.selected_form
				};
				axios
					.post(`${process.env.REACT_APP_GQL_ENDPOINT}`, mutation, {
						headers: {
							"x-hasura-admin-secret":
								"358c09bed3ce1bf3b4aa8b327bec0093"
						}
					})
					.then(res => {
						showNotification(
							"success",
							"Success",
							"Your account has been downgraded."
						);
						this.props.onClose();
					})
					.catch(err => console.log(err));
			}
		});
	};

	componentDidMount() {
		this.fetchProjects();
	}

	onClose = () => {
		this.setState({
			projects: [],
			project_forms: [],
			selected_project: "",
			selected_form: ""
		});
		this.props.onClose();
	};

	render() {
		return (
			<Drawer
				title="Downgrade current plan"
				onClose={this.onClose}
				visible={this.props.visible}
				maskClosable={false}
				width={"30%"}
				destroyOnClose={true}
			>
				<div style={{ margin: "1rem 0" }}>
					<Alert
						message="Free plan allows only one form across all projects. Select a form from any project to keep it active. All the other projects and forms will be archived."
						type="warning"
						showIcon
					/>
				</div>
				<Formik
					initialValues={{ selected_project: "", selected_form: "" }}
					validationSchema={DownGradeUserSchema}
					onSubmit={this.downGradeUser}
					enableReinitialize={true}
				>
					{({ values, errors, touched, setFieldValue }) => (
						<Form>
							<div className="form-item">
								<label>Project</label>
								<Select
									size="large"
									onChange={data =>
										setFieldValue(
											`selected_project`,
											data,
											true
										)
									}
									className={`w-100 ${
										errors.selected_project &&
										touched.selected_project
											? "has-error"
											: ""
									}`}
								>
									{this.state.projects.map(
										(project, index) => (
											<Select.Option
												value={project.id}
												key={index}
											>
												{project.name}
											</Select.Option>
										)
									)}
								</Select>
								<div style={{ minHeight: 28 }}>
									<ErrorMessage
										name="selected_project"
										component="div"
										className="error-message"
									/>
								</div>
							</div>
							<div className="form-item">
								<label>Form</label>
								<Select
									size="large"
									disabled={values.selected_project === ""}
									loading={this.state.loading}
									onFocus={() =>
										this.fetchForms(values.selected_project)
									}
									onChange={data =>
										setFieldValue(
											`selected_form`,
											data,
											true
										)
									}
									className={`w-100 ${
										errors.selected_form &&
										touched.selected_form
											? "has-error"
											: ""
									}`}
								>
									{this.state.project_forms.map(
										(form, index) => (
											<Select.Option
												value={form.form_id}
												key={index}
											>
												{form.name}
											</Select.Option>
										)
									)}
								</Select>
								<div style={{ minHeight: 28 }}>
									<ErrorMessage
										name="selected_form"
										component="div"
										className="error-message"
									/>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-end mt-3">
								<Button
									size="large"
									onClick={this.onClose}
									className="mr-3"
									type="default"
								>
									Keep PRO plan
								</Button>
								<Button
									size="large"
									type="primary"
									htmlType="submit"
								>
									Downgrade plan
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Drawer>
		);
	}
}

export default DowngradeUser;
