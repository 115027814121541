import React, { useEffect } from "react";
import { UPDATE_USER, GET_LATEST_BILLING } from "../../graphql";
import { Link } from "react-router-dom";
import { callAPI } from "../../utils";
import success from "../../assets/success-green.png";
import "./style.css";

export default function SuccessPage(props) {
	useEffect(() => {
		console.log(props.user);

		if (props.user.is_pro === "Pending") {
			let query = UPDATE_USER;

			let latestBilling;

			async function getLatestBilling() {
				let latestBillingQuery = GET_LATEST_BILLING;

				latestBilling = await callAPI(latestBillingQuery);

				console.log("latestBilling", latestBilling);
			}

			getLatestBilling().then(() => {
				query.variables = {
					objects: {
						is_pro: "true",
						stripe_id: latestBilling.billings[0].stripe_id
					}
				};

				callAPI(query);
			});

			setTimeout(() => {
				window.location.href = "/";
			}, 5000);
		} else {
			window.location.href = "/";
		}
	}, [props.user]);

	console.log(props);
	return (
		<div
			style={{
				position: "absolute",
				top: "0",
				left: "0",
				right: "0",
				bottom: "0",
				margin: "auto",
				width: "fit-content",
				height: "fit-content",
				justifyContent: "center"
			}}
		>
			<div style={{ width: "100%" }}>
				<div className="m-0 text-center">
					<img
						src={success}
						style={{
							width: "10rem"
						}}
						alt="Payment Success!"
					/>
				</div>
			</div>

			<div className="w-75 mx-auto text-center">
				<p id="payment-success">Your payment is successful</p>
				<p id="success-message">
					Thank you for your payment. If you are not automatically
					redirected to Your projects, click{" "}
					<Link to="/"> here </Link>
				</p>
			</div>
		</div>
	);
}
