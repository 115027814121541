import React from "react";
import { Button, Dropdown, Icon, Menu, Modal } from "antd";
import { Link } from "react-router-dom";
import { auth } from "../nhost.js";
import { NavContainer } from "./StyledComponents";

class Nav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleLogOut = () => {
		Modal.confirm({
			title: "Are you sure you want to Logout?",
			okText: "Yes",
			cancelText: "No",
			onOk: () => {
				auth.logout();
				sessionStorage.clear();
				this.props.history.push("/");
			}
		});
	};

	componentDidMount() {
		var HW_config = {
			selector: ".changelog", // CSS selector where to inject the badge
			account: "JrRdYJ"
		};
		window.Headway.init(HW_config);
	}

	render() {
		const profileMenu = (
			<Menu>
				<Menu.Item
					onClick={() => this.props.history.push("/profile")}
					id="change-password-button"
				>
					My Profile
				</Menu.Item>
				<Menu.Item
					onClick={() => this.props.history.push("/archived")}
					id="change-password-button"
				>
					Archives
				</Menu.Item>
				<Menu.Divider />
				<Menu.Item onClick={this.handleLogOut} id="logout-button">
					Logout
				</Menu.Item>
			</Menu>
		);

		return (
			<NavContainer>
				<div className="font-18 d-flex align-items-center">
					<Link
						to="/projects"
						className="text-decoration-none"
						style={{ lineHeight: "32px" }}
					>
						<strong>Form</strong>Lab
					</Link>
					<p className="changelog mb-0"></p>
				</div>
				<div className="d-flex align-items-center justify-content-center">
					<Dropdown overlay={profileMenu}>
						<Button>
							<div className="d-flex align-items-center justify-content-center">
								<Icon type="user" className="mr-2" />
								<Icon type="down" />
							</div>
						</Button>
					</Dropdown>
				</div>
			</NavContainer>
		);
	}
}

export default Nav;
