import styled from "styled-components";

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);
  padding: 16px;
`;

export const LinkBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
