import React from "react";
import BreadCrumb from "../../components/Breadcrumb";
import {Button, Card, Divider, Popconfirm, Table} from "antd";
import {ARCHIVE_FORM, GET_ALL_FORMS_INSIDE_PROJECT} from "../../graphql";
import { callAPI, showNotification } from "../../utils";
import moment from "moment";
import { LinkBtn } from "../../components/StyledComponents";
import { Link } from "react-router-dom";
import CreateForm from "./CreateForm";
import EditForm from "./EditForm";
import goProBg from "../../assets/go-pro-bg.png";
import noSubmissions from "../../assets/noSubmissions.svg";

class ProjectForms extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			create_visible: false,
			edit_visible: false,
			form_data: {},
			forms: [],
			projects_by_pk: {
				name: ""
			},
		};
	}

	fetchData = async () => {
		let query = GET_ALL_FORMS_INSIDE_PROJECT;
		query.variables = { project_id: Number(this.props.match.params.id) };
		let response = await callAPI(query);
		this.setState({
			projects_by_pk: response.projects_by_pk,
			forms: response.project_forms,
		});
	};

	archiveForm = async (event, record) => {
		let mutation = ARCHIVE_FORM;
		mutation.variables = {
			form_id: record.form_id,
			isActive: false
		};
		let result = await callAPI(mutation);
		if (result.errors) {
			showNotification(
				"error",
				"Something Went Wrong!",
				"Project Cannot Be Archived"
			);
		} else {
			this.fetchData();
		}
	};

	componentDidMount() {
		this.fetchData();
	}

	showEditDrawer = (event, data) => {
		this.setState({
			edit_visible: true,
			form_data: data
		});
	};

	render() {
		const columns = [
			{
				title: "Form Name",
				dataIndex: "name"
			},
			{
				title: "Submissions",
				dataIndex: "form_submissions_aggregate.aggregate.count"
			},
			{
				title: "Form ID",
				dataIndex: "form_id"
			},
			{
				title: "Created at",
				dataIndex: "created_at",
				render: record => moment(record).format("D MMM, YYYY hh:mm A")
			},
			{
				title: "Action",
				key: "action",
				render: (text, record) => (
					<React.Fragment>
						<LinkBtn
							style={{ color: "#1890ff" }}
							onClick={event => this.showEditDrawer(event, record)}
						>
							Edit
						</LinkBtn>
						<Divider type="vertical" />
						<Link
							className="text-decoration-none"
							to={`/form/${record.form_id}`}
						>
							View
						</Link>
						{this.props.user.is_pro === "true" && (
							<>
								<Divider type="vertical" />
								<Popconfirm
									title="Are you sure you want to archive this form?"
									onConfirm={event => this.archiveForm(event, record)}
									okText="Yes"
									cancelText="No"
								>
									<LinkBtn href="#" style={{ color: "red" }}>
										Archive
									</LinkBtn>
								</Popconfirm>
							</>
						)}
					</React.Fragment>
				)
			}
		];

		return (
			<div className="app-container">
				<BreadCrumb
					data={`/projects/${this.state.projects_by_pk.name ||
						"Project Name"}`}
					title={`Your Forms`}
				>
					{this.state.forms.length !== 0 && (
						<Button
							type="primary"
							size="large"
							onClick={() => this.setState({ create_visible: true })}
							disabled={this.props.user.is_pro !== "true" && this.props.user.no_forms > 0}
						>
							Create Form
						</Button>
					)}
				</BreadCrumb>
				<div className="mt-4">
					{this.state.forms.length === 0 && (
						<Card className="border rounded">
							<div className="d-flex flex-column align-items-center justify-content-center p-5">
								<img src={noSubmissions} alt="No Submissions" className="mt-5 mb-5"/>
								<h4 className="text-light-gray text-center mb-5">
									Looks like you haven’t created any form yet. Create your first form for this project by clicking below.
								</h4>
								<Button
									type="primary"
									size="large"
									onClick={() => this.setState({ create_visible: true })}
									disabled={this.props.user.is_pro !== "true" && this.props.user.no_forms > 0}
								>
									Create Form
								</Button>
							</div>
						</Card>
					)}
					{this.state.forms.length !== 0 && (
						<Table
							pagination={this.state.forms.length > 10}
							columns={columns}
							rowKey={record => record.id}
							dataSource={this.state.forms}
							className="border rounded"
						/>
					)}
				</div>
				
				{this.props.user.is_pro !== "true" && this.props.user.no_forms > 0 ? (
					<div className="d-flex align-items-center w-100 mt-4 mb-4 pl-4 rounded bg-light-blue">
						<img className="w-100 h-auto" src={goProBg} alt="Go Pro Illustration"/>
						<div className="position-absolute">
							<h4 className="text-blue font-weight-bold font-22">
								You are currently using free plan
							</h4>
							<p className="text-muted mb-4 font-18">
								Free plan allows only one project and form. To create more projects and forms, please upgrade to a PRO plan by clicking below
							</p>
							<Button type="primary" size="large" onClick={() => this.props.history.push(`/profile`)}>
								Check PRO plans
							</Button>
						</div>
					</div>
				) : null}

				{this.state.create_visible && (
					<CreateForm
						visible={this.state.create_visible}
						setVisible={visible =>
							this.setState({ create_visible: visible })
						}
						refetchData={this.fetchData}
						project_id={Number(this.props.match.params.id)}
					/>
				)}
				{this.state.edit_visible && (
					<EditForm
						visible={this.state.edit_visible}
						setVisible={visible =>
							this.setState({ edit_visible: visible })
						}
						data={this.state.form_data}
						refetchData={this.fetchData}
					/>
				)}
			</div>
		);
	}
}

export default ProjectForms;
