export const CREATE_PROJECT = {
	query: `
    mutation insert_projects($objects: [projects_insert_input!]!) {
      insert_projects(objects: $objects) {
        returning {
          project_id
          id
          name
        }
      }
    }
  `
};

export const FIND_USERNAME = {
	query: `
		query findUsername ($email:String!) {
			users(where: {display_name: {_eq: $email}}) {
				id
			}
		}
	`
};

export const GET_SUB_ID = {
	query: `
		query getSubId ($stripe_id: String!) {
			billings(where: {stripe_id: {_eq: $stripe_id} }) {
				id
				subscription_id
				end_date
			}
		}
	`
};

export const GET_USER_RESET_LINK = {
	query: `
		query users($link: uuid){
			users(where: {secret_token: {_eq: $link}}){
				id
				secret_token
			}
		}
	`
};

export const GET_USER_DETAILS = {
	query: `
    query users {
      users {
      	id
        display_name
        is_pro
		plan_type
		stripe_id
      }
      project_forms_aggregate {
				aggregate {
					count
				}
			}
    }
  `
};

export const GET_ALL_FORMS = {
	query: `
		query getAllForms {
			project_forms_aggregate {
				aggregate {
				count
				}
			}
		}
	`
};

export const GET_ALL_PROJECTS = {
	query: `
    query active_projects {
			projects(where: {isActive: {_eq: true}}) {
				id
				name
				project_id
				created_at
				project_forms_aggregate(where: {isActive: {_eq: true}}) {
					aggregate {
						count
					}
				}
			}
		}
  `
};

export const GET_ARCHIVED_PROJECTS = {
	query: `
    query archived_projects {
			projects(where: {project_forms: {isActive: {_eq: false}}}) {
				id
				name
				project_id
				created_at
				project_forms_aggregate(where: {isActive: {_eq: false}}) {
					aggregate {
						count
					}
				}
			}
		}
  `
};

export const ARCHIVE_PROJECT = {
	query: `
		mutation update_project_forms($project_id: uuid!, $isActive: Boolean!) {
			update_project_forms(where: {project: {project_id: {_eq: $project_id}}}, _set: {isActive: $isActive}) {
				returning {
					name
				}
			}
			update_projects(where: {project_id: {_eq: $project_id}}, _set: {isActive: $isActive}) {
				returning {
					project_id
					name
				}
			}
		}
  `
};

export const UN_ARCHIVE_PROJECT = {
	query: `
		mutation un_archive_project($project_id: uuid!) {
			update_project_forms(where: {project: {project_id: {_eq: $project_id}}}, _set: {isActive: true}) {
				returning {
					name
				}
			}
			update_projects(where: {project_id: {_eq: $project_id}}, _set: {isActive: true}) {
				returning {
					project_id
					name
				}
			}
		}
	`
};

export const ARCHIVE_FORM = {
	query: `
		mutation update_project_forms($form_id: uuid!, $isActive: Boolean!) {
			update_project_forms(where: {form_id: {_eq: $form_id}}, _set: {isActive: $isActive}) {
				returning {
					name
				}
			}
		}
  `
};

export const UN_ARCHIVE_FORM = {
	query: `
		mutation un_archive_project($form_id: uuid!) {
			update_project_forms(where: {form_id: {_eq: $form_id}}, _set: {isActive: true}) {
				returning {
					name
				}
			}
			update_projects(where: {project_forms: {form_id: {_eq: $form_id}}}, _set: {isActive: true}) {
				affected_rows
			}
		}
	`
};

export const GET_ALL_FORMS_INSIDE_PROJECT = {
	query: `
    query project_forms ($project_id: Int!) {
			projects_by_pk(id: $project_id) {
				name
			}
      project_forms(where: {project_id: {_eq: $project_id}, isActive: {_eq: true}}) {
        name
        project_id
        project{
          name
        }
        form_id
        form_submissions_aggregate {
          aggregate {
            count
          }
        }
        created_at
      }
    }
  `
};

export const GET_ARCHIVED_FORMS = {
	query: `
    query project_forms($project_id: Int!) {
			projects_by_pk(id: $project_id) {
				id
				project_id
				name
				isActive
			}
			project_forms(where: {project_id: {_eq: $project_id}, isActive: {_eq: false}}) {
				name
				form_id
				created_at
				form_submissions_aggregate {
					aggregate {
						count
					}
				}
			}
		}
  `
};

export const CREATE_FORM = {
	query: `
    mutation insert_project_forms($objects: [project_forms_insert_input!]!){
      insert_project_forms(objects: $objects) {
          affected_rows
      }
    }
  `
};

export const EDIT_FORM_NAME = {
	query: `
    mutation update_project_forms($name: String!, $form_id: uuid!){
      update_project_forms(_set: {name: $name}, where: {form_id: {_eq: $form_id}}) {
        affected_rows
      }
    }
  `
};

export const EDIT_PROJECT_NAME = {
	query: `
    mutation update_projects($name: String!, $project_id: uuid!){
      update_projects(_set: {name: $name}, where: {project_id: {_eq: $project_id}}) {
        affected_rows
      }
    }
  `
};

export const GET_ALL_SUBMISSIONS = {
	query: `
    query form_submissions($form_id: uuid!, $is_active: Boolean!) {
			project_forms_by_pk(form_id: $form_id) {
				name
				project {
					id
					name
				}
			}
			form_submissions(where: {form_id: {_eq: $form_id}, _and: {is_active: {_eq: $is_active}}}) {
				created_at
				data
				id
				form_id
				project_form {
					name
				}
			}
		}
  `
};

export const DELETE_SUBMISSION = {
	query: `
		mutation update_form_submissions($id: Int!){
			update_form_submissions(where: {id: {_eq: $id}}, _set: {is_active: false}) {
				returning {
					id
					is_active
				}
			}
		}
	`
};

export const SUBMIT_FORM_DATA = {
	query: `
    mutation insert_form_submissions ($objects: [form_submissions_insert_input!]!){
      insert_form_submissions(objects: $objects) {
        affected_rows
      }
    }
  `
};

export const GET_LATEST_BILLING = {
	query: `
	query getLatestBilling {
		__typename
		billings(limit: 1, order_by: {created_at: desc}) {
			id
			stripe_id
		}
	}
	`
};

export const UPDATE_BILLING = {
	query: `
    mutation insert_billings($objects: [billings_insert_input!]!) {
      insert_billings(objects: $objects) {
        affected_rows
      }
    }
  `
};

export const UPDATE_USER_HAVING_EMAIL = {
	query: `
    mutation update_users($objects: users_set_input, $email: String) {
			update_users(_set: $objects, where: { display_name: { _eq: $email } }) {
				affected_rows
			}
		}
  `
};

export const UPDATE_USER = {
	query: `
    mutation update_users($objects: users_set_input) {
			update_users(_set: $objects, where: {}) {
				affected_rows
			}
		}
  `
};

export const DOWNGRADE_USER = {
	query: `
		mutation DowngradeUser($user_id: uuid, $project_id: Int, $form_id: uuid) {
			update_projects(where: {user_id: {_eq: $user_id}, _and: {id: {_neq: $project_id}}}, _set: {isActive: false}) {
				affected_rows
			}
			update_project_forms(where: {form_id: {_neq: $form_id}, project: {user: {id: {_eq: $user_id}}}}, _set: {isActive: false}) {
				affected_rows
			}
			update_users(where: {id: {_eq: $user_id}}, _set: {is_pro: "false", plan_type: "free"}) {
				affected_rows
			}
		}
	`
};
